import React, { useReducer } from "react";
import { Buffer } from "buffer";
import axiosInstance from "../../utilities/Axios";

type User = {
  id: number;
  role: string;
  username: string;
  remember_token: null;
  created_at: string;
  updated_at: string;
  permissions: {
    [key: string]: string[];
  };
};

type IAuth = {
  auth: {
    access_token: string;
    user: User;
    is_logged_in: boolean;
  } | null;
  login: any;
  logout: any;
  init: any;
  hasPermission: (module: string, permissions: string[]) => boolean;
};

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case "login":
      return action.payload;
    case "logout":
      return null;
  }
  return state;
};

export const AuthContext = React.createContext<IAuth>({
  auth: null,
  login: (user: User) => { },
  logout: () => { },
  init: () => { },
  hasPermission: (module: string, permissions: string[]) => false,
});

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const [authState, dispatch] = useReducer(reducer, false);

  AuthContext.displayName = "Auth Context";

  const login = async (data: any) => {

    localStorage.setItem("token", data.access_token.token);

    axiosInstance.defaults.headers.common["Authorization"] =
      "Bearer " + data.access_token.token;

    dispatch({
      type: "login",
      payload: {
        user: data.user,
        access_token: data.access_token.token,
        is_logged_in: true,
      },
    });
  };

  const logout = () => {
    localStorage.removeItem("token");

    axiosInstance.defaults.headers.common["Authorization"] = "";

    dispatch({ type: "logout", payload: { isLoggedIn: false } });

    if (!window.location.href.includes("/login")) {
      window.location.href = "/login";
    }
  };

  const init = async () => {
    try {
      let role = "sadmin";

      const store_data = localStorage.getItem("token");

      const token: any = store_data ? store_data : null;      

      if (token) {

        try {
          let buff = Buffer.from("" + token.split(".")[1], "base64");
          let base64ToStringNew = buff.toString("ascii");
  
          const tokenData = JSON.parse(base64ToStringNew);
          role = tokenData.user_role;
        } catch (err) {
          console.error(err);
        }

        axiosInstance.defaults.headers.common["Authorization"] =
          "Bearer " + token;

        const res = await axiosInstance.post("/v1/account/me");

        const payload = {
          user: res.data.result.user,
          token: token,
          role: res.data.result.user.role,
          permissions: res.data.result.permissions,
        };

        if (window.location.pathname == "" || window.location.pathname == "/") {
          window.location.href = "/admin";
        }
        dispatch({ type: "login", payload: payload });
      } else {
        dispatch({ type: "logout", payload: { isLoggedIn: false } });
        logout();
      }
    } catch (err: any) {
      console.error(err);

      axiosInstance.defaults.headers.common["Authorization"] = "";

      localStorage.removeItem("token");
      dispatch({ type: "logout", payload: { isLoggedIn: false } });
      logout();
    }
  };

  const hasPermission = (module: string, permissions: string[]) => {
    if (!authState || !authState.permissions) {
      return false;
    }

    if (authState.permissions['ALL']) {
      const userPermissions = authState.permissions['ALL'];
      if (userPermissions.includes('ANY')) {
        return true;
      }
      return permissions.every((perm) => userPermissions.includes(perm));
    }

    const userPermissions = authState.permissions[module] || [];
    return permissions.every((perm) => userPermissions.includes(perm));
  };

  return (
    <AuthContext.Provider
      value={{
        auth: authState,
        login: login,
        logout: logout,
        init: init,
        hasPermission: hasPermission,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
